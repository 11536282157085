import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dynamic from '../components/Dynamic';
import Layout from '../components/layout';
import SEO from '../components/seo';
import marketo from '../utils/marketo.munchkin';
import bodyTagId from '../utils/bodyTagId';
import killServiceWorkers from '../utils/killServiceWorkers';
import Navbar24 from '../components/Shared/state-of-api-2024/NavBar24';
import FooterSota from '../components/Shared/state-of-api-2024/FooterSota';
import PrevNextSection from '../components/Shared/state-of-api-2024/PrevNextSection';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
const BackgroundContainer = styled.div`
  background-color: #0d1629;
`;
const ContentHolder = styled.div`
  @media (min-width: 900px) {
    background: url('https://voyager.postman.com/icon/state-of-api/postman-state-of-api-bg-dark.svg'), linear-gradient(180deg, #0D1629 3%, #272D67 35%, #0D1629 71%, #272D67 100%);
    // background: linear-gradient(180deg, #0D1629 3%, #272D67 35%, #0D1629 71%, #272D67 100%);
    background-repeat: repeat, no-repeat;
    background-size: 50px, cover;
  }
  // max-width: 1920px;
  margin: 0 auto;
`;

const stateOfAPI2024Template = ({ data }) => {
  if (!data || !data.dynamicPage) {
    return <div>Loading...</div>;
  }

  const components = JSON.parse(data.dynamicPage.content);

  return (
    <Layout navbarType={data.dynamicPage.navbarType} navbarBackground={data.dynamicPage.navbarBackground}>
      {bodyTagId(data.dynamicPage.bodyTagId)}
      <SEO title={data.dynamicPage.title} description={data.dynamicPage.description} url={data.dynamicPage.url} canonical={data.dynamicPage.canonical} image={data.dynamicPage.seoPreviewImg} noindex={data.dynamicPage.noindex} />
      <BackgroundContainer>
          <Navbar24 />
        <ContentHolder>
          {components.map((item) => (
            <div key={pmUuid()}>{Dynamic[item.type] ? Dynamic[item.type](item) : ''}</div>
          ))}
          <PrevNextSection />
        </ContentHolder>
        <FooterSota />
      </BackgroundContainer>
      {marketo()}
      {killServiceWorkers()}
    </Layout>
  );
};

export default stateOfAPI2024Template;

export const query = graphql`
  query ($id: String!) {
    dynamicPage(id: { eq: $id }) {
      id
      url
      content
      description
      title
      canonical
      noindex
      bodyTagId
      navbarType
      navbarBackground
      seoPreviewImg
    }
    globalValues {
      content
    }
  }
`;

stateOfAPI2024Template.propTypes = {
  data: PropTypes.shape({
    dynamicPage: PropTypes.shape({
      content: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      canonical: PropTypes.string,
      noindex: PropTypes.bool,
      navbarType: PropTypes.string,
      navbarBackground: PropTypes.string,
      seoPreviewImg: PropTypes.string
    }),
    globalValues: PropTypes.shape({
      content: PropTypes.string
    })
  }).isRequired
};
